export default {
    namespaced: true,
    state: {
        services: {
            intranet: {
                name: 'Intranet',
                path: '/',
                icon: 'mdi-home',
                roles: ['ANSATT', 'FRIVILLIG', 'BRUKER'],
            },
            sorg: {
                name: 'Sorgtjenesten',
                path: '/sorg',
                icon: 'mdi-charity',
                roles: ['ANSATT', 'FRIVILLIG_SORG', 'BRUKER_SORG'],
            },
            hjemme: {
                name: 'Hjemmetjeneste',
                path: '/hjemme',
                icon: 'mdi-home-group',
                roles: ['ANSATT', 'FRIVILLIG_HJEMME', 'BRUKER_HJEMME'],
            },
            moo: {
                name: 'Møtested Oslo Øst',
                path: '/moo',
                icon: 'mdi-account-group',
                roles: ['ANSATT', 'FRIVILLIG_MOTESTED', 'BRUKER_MOTESTED'],
            },
        },

        menu: [
            {
                title: 'Intranet',
                path: '/',
                component: 'Home',
                service: 'intranet',

                items: [
                    { title: 'Aktuelt', icon: 'calendar', path: '/aktuelt', component: 'articles/Page' },

                    {
                        title: 'Arrangement',
                        icon: '',
                        path: '/arrangement',
                        component: 'activities/Page',
                    },

                    {
                        title: 'Frivillige',
                        icon: 'card-account-mail-outline',
                        path: '/frivillige/',
                        component: 'persons/List',
                        params: {
                            role: 'FRIVILLIG',
                        },
                        roles: ['ANSATT'],
                    },

                    {
                        title: 'Rekruttering',
                        icon: 'card-account-mail-outline',
                        path: '/rekruttering/',
                        component: 'persons/List',
                        params: {
                            role: 'FRIVILLIGIPROSESS',
                        },
                        roles: ['ANSATT'],
                    },

                    {
                        title: 'Kontakter',
                        icon: 'card-account-mail-outline',
                        path: '/kontakter/',
                        component: 'persons/List',
                        params: {
                            role: 'KONTAKT',
                        },
                        roles: ['ANSATT'],
                    },

                    {
                        title: 'Ansatte',
                        icon: 'card-account-mail-outline',
                        path: '/ansatte/',
                        component: 'persons/List',
                        right: true,
                        params: {
                            role: 'ANSATT',
                        },
                        roles: ['ANSATT'],
                    },

                    {
                        title: 'Administrer',
                        icon: 'settings',
                        path: '/administrer/',
                        component: 'Settings',
                        roles: ['ANSATT'],
                    },
                ],
            },

            {
                title: 'Sorgtjenesten',
                path: '/sorg/',
                component: () => import('@/pages/grief/Page.vue'),
                service: 'sorg',
                roles: ['ANSATT', 'FRIVILLIG_SORG', 'BRUKER_SORG'],

                items: [
                    {
                        title: 'Kalender',
                        icon: 'calendar',
                        path: '/sorg/kalender/',
                        component: 'grief/Calendar',
                        roles: ['ANSATT', 'FRIVILLIG_SORG', 'BRUKER_SORG'],
                    },

                    {
                        title: 'Grupper',
                        icon: 'card-account-multiple',
                        path: '/sorg/grupper/',
                        component: () => import('@/pages/grief/List.vue'),
                        roles: ['ANSATT'],
                    },

                    {
                        title: 'Frivillige',
                        icon: 'card-account-multiple',
                        path: '/sorg/frivillige/',
                        component: 'persons/List',
                        params: {
                            role: 'FRIVILLIG_SORG',
                        },
                        roles: ['ANSATT'],
                    },

                    {
                        title: 'Brukere',
                        icon: 'card-account-multiple',
                        path: '/sorg/brukere/',
                        component: 'persons/List',
                        params: {
                            role: 'BRUKER_SORG',
                        },
                        roles: ['ANSATT'],
                    },

                    {
                        title: 'Venteliste',
                        icon: 'card-account-multiple',
                        path: '/sorg/venteliste/',
                        component: () => import('@/pages/grief/Queue.vue'),
                        roles: ['ANSATT'],
                    },

                    {
                        title: 'Rapport',
                        icon: 'file-chart-outline',
                        path: '/sorg/rapporter/',
                        component: 'grief/ReportsPage',
                        roles: ['ANSATT', 'FRIVILLIG_SORG'],
                    },

                    {
                        title: 'Administrer',
                        icon: 'settings',
                        path: '/sorg/administrer/',
                        component: () => import('@/pages/grief/Settings.vue'),
                        roles: ['ANSATT'],
                        right: true,
                    },
                ],
            },

            {
                title: 'Hjemmetjenesten',
                path: '/hjemme',
                component: 'visit/Page',
                service: 'hjemme',

                items: [
                    {
                        title: 'Koblinger',
                        path: '/hjemme/koblinger',
                        name: '/hjemme/koblinger',
                        component: 'visit/List',
                        roles: ['ANSATT'],
                    },

                    {
                        title: 'Brukere',
                        icon: 'card-account-multiple',
                        path: '/hjemme/brukere/',
                        component: 'persons/List',
                        params: {
                            role: 'BRUKER_HJEMME',
                        },
                        roles: ['ANSATT'],
                    },

                    {
                        title: 'Frivillige',
                        icon: 'card-account-multiple',
                        path: '/hjemme/frivillige/',
                        component: 'persons/List',
                        params: {
                            role: 'FRIVILLIG_HJEMME',
                        },
                        roles: ['ANSATT'],
                    },

                    {
                        title: 'Venteliste',
                        icon: 'card-account-multiple',
                        path: '/hjemme/venteliste/',
                        component: 'visit/Queue',
                        roles: ['ANSATT'],
                    },

                    {
                        title: 'Rapport',
                        icon: 'file-chart-outline',
                        path: '/hjemme/rapporter/',
                        component: 'visit/ReportsPage',
                        roles: ['ANSATT', 'FRIVILLIG_HJEMME'],
                    },

                    {
                        title: 'Administrer',
                        icon: 'settings',
                        path: '/hjemme/administrer/',
                        component: () => import('@/pages/visit/Settings.vue'),
                        roles: ['ANSATT'],
                        right: true,
                    },
                ],
            },

            {
                title: 'Møtested Oslo Øst',
                path: '/moo/',
                component: 'moo/Page',
                service: 'moo',

                items: [
                    {
                        title: 'Frivillige',
                        icon: 'card-account-multiple',
                        path: '/moo/frivillige/',
                        component: 'persons/List',
                        params: {
                            role: 'FRIVILLIG_MOTESTED',
                        },
                        roles: ['ANSATT'],
                    },

                    {
                        title: 'Brukere',
                        icon: 'card-account-multiple',
                        path: '/moo/brukere/',
                        component: 'persons/List',
                        params: {
                            role: 'BRUKER_MOTESTED',
                        },
                        roles: ['ANSATT'],
                    },
                ],
            },
        ],
        routes: [
            {
                path: '/ansatt/ny',
                name: '/ansatt/ny',
                component: () => import('@/pages/persons/New.vue'),
                props: { role: 'ansatt' },
            },
            {
                path: '/ansatt/:id',
                name: '/ansatt',
                component: () => import('@/pages/persons/Person.vue'),
                props: (route) => ({ role: 'ansatt', id: route.params.id }),
            },
            {
                path: '/frivillig/ny',
                name: '/frivillig/ny',
                component: () => import('@/pages/persons/New.vue'),
                props: { role: 'frivillig' },
            },
            {
                path: '/frivillig/:id',
                name: '/frivillig',
                component: () => import('@/pages/persons/Person.vue'),
                props: (route) => ({ role: 'frivillig', id: route.params.id }),
            },
            {
                path: '/bruker/ny',
                name: '/bruker/ny',
                component: () => import('@/pages/persons/New.vue'),
                props: { role: 'bruker' },
            },
            {
                path: '/bruker/:id',
                name: '/bruker',
                component: () => import('@/pages/persons/Person.vue'),
                props: (route) => ({ role: 'bruker', id: route.params.id }),
            },

            {
                path: '/arrangement/ny',
                name: '/Arrangement/ny',
                component: () => import('@/pages/activities/New.vue'),
            },

            {
                path: '/arrangementer',
                name: '/arrangementer',
                component: () => import('@/pages/activities/List.vue'),
            },

            {
                path: '/arrangement/:id',
                name: '/arrangement-id',
                component: () => import('@/pages/activities/View.vue'),
                props: (route) => ({ id: route.params.id }),
            },

            {
                path: '/arrangement/:id/rediger',
                name: '/arrangement/rediger',
                component: () => import('@/pages/activities/Activity.vue'),
                props: (route) => ({ id: route.params.id }),
            },

            {
                path: '/sorg/gruppe/ny',
                name: '/sorg/gruppe/ny',
                component: () => import('@/pages/grief/New.vue'),
            },
            {
                path: '/sorg/gruppe/:id',
                name: '/sorg/gruppe',
                component: () => import('@/pages/grief/Group.vue'),
                props: (route) => ({ id: route.params.id }),
            },

            {
                path: '/sorg/gruppemote/:id',
                name: '/sorg/gruppemote',
                component: () => import('@/pages/activities/View.vue'),
                props: (route) => ({ id: route.params.id }),
            },
            {
                path: '/sorg/gruppemote/:id/rediger',
                name: '/sorg/gruppemote/rediger',
                component: () => import('@/pages/activities/Activity.vue'),
                props: (route) => ({ id: route.params.id }),
            },

            {
                path: '/sorg/venteliste/:id',
                name: '/sorg/venteliste/person',
                component: () => import('@/pages/persons/Person.vue'),
                props: (route) => ({ id: route.params.id, role: 'bruker' }),
            },

            {
                path: '/sorg/bruker/ny',
                name: '/sorg/bruker/ny',
                component: () => import('@/pages/persons/New.vue'),
                props: (/*route*/) => ({ role: 'bruker_sorg' }),
            },

            {
                path: '/sorg/rapporter/liste',
                name: '/sorg/rapporter/liste',
                component: () => import('@/pages/grief/Reports'),
            },

            {
                path: '/sorg/administrer/gruppetyper',
                name: '/sorg/administrer/gruppetyper',
                component: () => import('@/pages/grief/Types'),
            },
            {
                path: '/sorg/administrer/gruppemetoder',
                name: '/sorg/administrer/gruppemetoder',
                component: () => import('@/pages/grief/Methods'),
            },

            {
                path: '/hjemme/ny',
                name: '/hjemme/ny',
                component: () => import('@/pages/visit/New.vue'),
            },
            {
                path: '/hjemme/koblinger/:type',
                name: '/hjemme/koblinger-type',
                component: () => import('@/pages/visit/List.vue'),
                props: (route) => ({ menyType: route.params.type }),
            },

            {
                path: '/hjemme/:id',
                name: '/hjemme/tjeneste',
                component: () => import('@/pages/visit/Visit.vue'),
                props: (route) => ({ id: route.params.id }),
            },

            {
                path: '/hjemme/rapporter/liste',
                name: '/hjemme/rapporter/liste',
                component: () => import('@/pages/visit/Reports'),
            },

            {
                path: '/hjemme/administrer/type',
                name: '/hjemme/administrer/type',
                component: () => import('@/pages/visit/Types'),
            },

            {
                path: '/moo',
                name: '/moo',
                component: () => import('@/pages/Todo.vue'),
            },

            {
                path: '/aktuelt/ny',
                name: '/aktuelt/ny',
                component: () => import('@/pages/articles/Article.vue'),
                props: () => ({ id: null }),
            },

            {
                path: '/aktuelt/:id',
                name: '/aktuelt/id',
                component: () => import('@/pages/articles/Article.vue'),
                props: (route) => ({ id: route.params.id }),
            },

            {
                path: '/administrer/moterom',
                name: '/administrer/moterom',
                component: () => import('@/pages/activities/Rooms'),
            },
            {
                path: '/administrer/arrangementetstyper',
                name: '/administrer/arrangementetstyper',
                component: () => import('@/pages/activities/Types'),
            },
        ],
        pages: [],

        breadcrumbs: [],
    },
    mutations: {
        services(state, services) {
            state.services = services;
        },
        menu(state, menu) {
            state.menu = menu;
        },
        breadcrumbs(state, breadcrumbs) {
            state.breadcrumbs = breadcrumbs;
        },
    },
    actions: {
        setUser(context, user) {
            const roles = [];
            for (const role of user.roller) {
                roles.push(role.id);
            }

            const services = {};
            for (const id in context.state.services) {
                const service = context.state.services[id];
                if (service.roles) {
                    let access = false;
                    for (const role of service.roles) {
                        if (roles.indexOf(role) !== -1) {
                            access = true;
                            break;
                        }
                    }
                    if (!access) {
                        continue;
                    }
                }

                services[id] = service;
            }

            function addMenuItems(items, roles) {
                const menu = [];
                for (const item of items) {
                    if (item.roles) {
                        let access = false;
                        for (const role of item.roles) {
                            if (roles.indexOf(role) !== -1) {
                                access = true;
                                break;
                            }
                        }
                        if (!access) {
                            continue;
                        }
                    }

                    if (item.items) {
                        item.items = addMenuItems(item.items, roles);
                    }
                    menu.push(item);
                }
                return menu;
            }

            const menu = addMenuItems(context.state.menu, roles);
            context.commit('services', services);
            context.commit('menu', menu);
        },
        setPage(context, path) {
            const breadcrumbs = [];
            const slugs = path.split('/');

            if (slugs[0].length == 0) {
                slugs.shift();
            }
            if (slugs.length > 1) {
                switch (slugs[0]) {
                    /*case 'arrangement':
                        switch (slugs[1]) {
                            case 'liste':
                                breadcrumbs.push({
                                    text: 'Aktiviteter',
                                    to: '/aktiviteter/liste',
                                    exact: true,
                                });
                                break;
                        }
                        break;

                    case 'arrangement':
                        breadcrumbs.push({
                            text: 'Arrangement',
                            to: '/aktiviteter/liste',
                            exact: true,
                        });
                        break;
                    */

                    case 'aktuelt':
                        breadcrumbs.push({
                            text: 'Aktuelt',
                            to: '/aktuelt',
                            exact: true,
                        });
                        break;

                    case 'frivillige':
                    case 'frivillig':
                        breadcrumbs.push({
                            text: 'Frivillige',
                            to: '/frivillige',
                            exact: true,
                        });
                        break;

                    case 'ansatte':
                    case 'ansatt':
                        breadcrumbs.push({
                            text: 'Ansatte',
                            to: '/ansatte',
                            exact: true,
                        });
                        break;

                    case 'sorg':
                        breadcrumbs.push({
                            text: 'Sorgtjenesten',
                            to: '/sorg',
                            exact: true,
                        });

                        switch (slugs[1]) {
                            case 'grupper':
                            case 'gruppe':
                                breadcrumbs.push({
                                    text: 'Grupper',
                                    to: '/sorg/grupper',
                                    exact: true,
                                });
                                break;

                            case 'kalender':
                            case 'gruppemote':
                                breadcrumbs.push({
                                    text: 'Kalender',
                                    to: '/sorg/kalender',
                                    exact: true,
                                });
                                break;

                            case 'frivillige':
                                breadcrumbs.push({
                                    text: 'Frivillige',
                                    to: '/sorg/frivillige',
                                    exact: true,
                                });
                                break;

                            case 'brukere':
                                breadcrumbs.push({
                                    text: 'Brukere',
                                    to: '/sorg/brukere',
                                    exact: true,
                                });
                                break;

                            case 'venteliste':
                                breadcrumbs.push({
                                    text: 'Venteliste',
                                    to: '/sorg/venteliste',
                                    exact: true,
                                });
                                break;

                            case 'rapporter':
                                breadcrumbs.push({
                                    text: 'Rapporter',
                                    to: '/sorg/rapporter',
                                    exact: true,
                                });
                                break;

                            case 'administrer':
                                breadcrumbs.push({
                                    text: 'Administrer',
                                    to: '/sorg/administrer',
                                    exact: true,
                                });
                                break;
                        }
                        break;

                    case 'hjemme':
                        breadcrumbs.push({
                            text: 'Hjemmetjeneste',
                            to: '/hjemme',
                            exact: true,
                        });

                        switch (slugs[1]) {
                            case 'koblinger':
                                breadcrumbs.push({
                                    text: 'Koblinger',
                                    to: '/hjemme/koblinger',
                                    exact: true,
                                });
                                break;

                            case 'frivillige':
                                breadcrumbs.push({
                                    text: 'Frivillige',
                                    to: '/hjemme/frivillige',
                                    exact: true,
                                });
                                break;

                            case 'brukere':
                                breadcrumbs.push({
                                    text: 'Brukere',
                                    to: '/hjemme/brukere',
                                    exact: true,
                                });
                                break;

                            case 'venteliste':
                                breadcrumbs.push({
                                    text: 'Venteliste',
                                    to: '/hjemme/venteliste',
                                    exact: true,
                                });
                                break;

                            case 'rapporter':
                                breadcrumbs.push({
                                    text: 'Rapporter',
                                    to: '/hjemme/rapporter',
                                    exact: true,
                                });
                                break;

                            case 'administrer':
                                breadcrumbs.push({
                                    text: 'Administrer',
                                    to: '/hjemme/administrer',
                                    exact: true,
                                });
                                break;
                        }
                        break;

                    case 'administrer':
                        breadcrumbs.push({
                            text: 'Administrer',
                            to: '/administrer',
                            exact: true,
                        });
                        break;
                }
            }

            context.commit('breadcrumbs', breadcrumbs);
        },
    },
    getters: {
        services(state) {
            return state.services;
        },

        menu(state) {
            return state.menu;
        },

        pages(state) {
            return state.pages;
        },

        routes(state) {
            const routes = [];
            for (const item of state.menu) {
                const items = [item];
                if (item.items) {
                    for (const subitem of item.items) {
                        items.push(subitem);
                        if (subitem.items) {
                            for (const subsubitem of subitem.items) {
                                items.push(subsubitem);
                            }
                        }
                    }
                }

                for (const item of items) {
                    if (item.path && item.component) {
                        routes.push({
                            path: item.path,
                            name: item.path,
                            component: typeof item.component == 'string' ? () => import('@/pages/' + item.component + '.vue') : item.component,
                            props: item.params || {},
                        });
                    }
                }
            }

            return routes.concat(state.routes);
        },
        breadcrumbs(state) {
            return state.breadcrumbs;
        },
    },
};
